import { graphql, Link, StaticQuery } from "gatsby"
import React from "react"
import "../layouts/base-styles.scss"
import "../layouts/LayoutCommon"
import { baseClass, childClass } from "../layouts/LayoutCommon";
import SiteHeader from "components/headers/SiteHeader";
import { ArticleMetaData } from "@ViewModels";
import ArticleLinkCard from "components/cards/ArticleLinkCard";
import { MdxConnection } from "@GraphQLModels";
import SiteFooter from "components/footer/SiteFooter";
import PageMeta from "components/texty/PageMeta";


interface IProps {
  data: {
    allMdx: MdxConnection;
  }
}

export default function AllArticles(props: IProps) {
  const articles = Array.from(props.data.allMdx.edges.map(edge => ArticleMetaData.from(edge.node)));
  articles.sort((a, b) => a.date.getTime() - b.date.getTime());
  return (
    <div className={baseClass}>
      <PageMeta
        title='Articles'
        route='/all-articles'>
      </PageMeta>
      <SiteHeader />
      <section className={`${childClass('mdx')} ${childClass('container')}`}>
        <h1 className={childClass('nice-h1')}>Articles</h1>
        <ul className={childClass('contents-list')}>
          {articles.map((article, index) => {
            return (
              <li key={index} className={childClass('contents-list-item')}>
                <ArticleLinkCard articleMeta={article} />
              </li>
            )
          })}
        </ul>
      </section>
      <SiteFooter />
    </div >
  )
}

export const pageQuery = graphql`
  query AllArticlesQuery {
      allMdx(
          filter: { frontmatter: { templateKey: { eq: "article-content" } } }
        ) {
        edges {
          node {
            fields {
              route
            }
            frontmatter {
              title
              standfirst
              date
              mainImage
              templateKey
            }
          }
        }
      }
    }
`

